import "./Main.css";
import React from "react";

import Profile from "../Profile/Profile";
import Bio from "../Bio/Bio";
import SocialIcons from "../SocialIcons/SocialIcons";
import FileUpload from "../FileUpload/FileUpload";
import Lead from "../Lead/Lead";
import Links from "../Links/Links";

const Main = ({
  user,
  aboutUser,
  handleAboutMeEdit,
  handleEditProfileEdit,
  handleSocialIconsEdit,
  handleLinksEdit,
  handleFilesEdit,
  handleShowProfileImage,
  defaultProfileImage,
  handleExchangeContact,
  handleAddLink,
  handleFilesAdd,
}) => {
  return (
    <main id="main">
      <Profile
        user={user}
        handleEditProfileEdit={handleEditProfileEdit}
        defaultProfileImage={defaultProfileImage}
        handleShowProfileImage={handleShowProfileImage}
      />
      <Lead handleExchangeContact={handleExchangeContact} />

      <section className="page-section">
        <div className="container">
          <div className="row">
            <Bio handleAboutMeEdit={handleAboutMeEdit} aboutUser={aboutUser} />
            <SocialIcons handleSocialIconsEdit={handleSocialIconsEdit} />
          </div>
        </div>
      </section>

      <Links
        uniqueid={user.uniqueid}
        handleLinksEdit={handleLinksEdit}
        handleAddLink={handleAddLink}
      />
      <FileUpload
        uniqueid={user.uniqueid}
        handleFilesEdit={handleFilesEdit}
        handleFilesAdd={handleFilesAdd}
      />
    </main>
  );
};

export default Main;
