import React from "react";
import "./Bio.css";

const Bio = ({ handleAboutMeEdit, aboutUser }) => {
  const handleAboutMeEditClick = () => {
    handleAboutMeEdit({ bio: aboutUser.bio });
    console.log(aboutUser);
  };

  return (
    <div className="col-md-7 col-lg-6 mb-sm-20">
      <div className="about__title">
        <h4 className="about__heading">About</h4>
        <div className="about__edit_dark about__edit-btn">
          <button
            className="about__button"
            onClick={handleAboutMeEditClick}
          ></button>
          {/* <img src={edit} alt="Edit" title="Edit Profile" /> */}
        </div>
      </div>
      <p className="gray about__me">{aboutUser.bio}</p>
    </div>
  );
};

export default Bio;
