import "./ExchangeContactPopup.css";
import React, { useState } from "react";
import Popup from "../Popup/Popup";

const ExchangeContactPopup = ({ isOpen, onClose }) => {
  const [isRegistered, setIsRegistered] = useState(true);

  const handleIsUserRegistered = (event) => {
    if (event.target.value === "yes") setIsRegistered(true);
    else if (event.target.value === "no") setIsRegistered(false);
  };

  return (
    <Popup title="Exchange Contact" isOpen={isOpen} onClose={onClose}>
      <p>Do you have a LinkWi profile?</p>
      <input
        className="exchange_yes"
        type="radio"
        name="exchange_yes_no"
        value="yes"
        onChange={handleIsUserRegistered}
        defaultChecked
      />
      Yes
      <input
        className="exchange_no"
        type="radio"
        name="exchange_yes_no"
        value="no"
        onChange={handleIsUserRegistered}
      />
      No
      {isRegistered ? (
        <div className="exchange__linkwi-user_yes">
          <input
            id="exchange_username_input"
            className="popup__form-input popup__form-input_type_linkwi_username"
            name="exchange_username"
            type="text"
            placeholder="LinkWi username"
            required
          />

          <span className="input_error exchange_username_input-error"></span>
        </div>
      ) : (
        <div className="exchange__linkwi-user_no">
          <input
            id="exchange-firstname-input"
            className="popup__form-input"
            name="exchange_firstname"
            type="text"
            placeholder="First name"
          />
          <span className="input_error exchange-firstname-input-error"></span>
          <input
            id="exchange-lastname-input"
            className="popup__form-input"
            name="exchange_lastname"
            type="text"
            placeholder="Last Name"
          />
          <span className="input_error exchange-lastname-input-error"></span>
          <input
            id="exchange-email-input"
            className="popup__form-input"
            name="exchange_email"
            type="email"
            placeholder="Email address"
          />
          <span className="input_error exchange-email-input-error"></span>
          <input
            id="exchange-contact-input"
            className="popup__form-input"
            name="exchange_contact"
            type="tel"
            placeholder="Contact Number"
          />
          <span className="input_error exchange-contact-input-error"></span>
          <input
            id="exchange-met_at-input"
            className="popup__form-input"
            name="exchange_met_at"
            type="text"
            placeholder="Where did you meet?"
          />
          <span className="input_error exchange-met_at-input-error"></span>
        </div>
      )}
    </Popup>
  );
};

export default ExchangeContactPopup;
