import React from "react";
import "./Popup.css";
import close_icon from "../../images/icons/close-icon.svg";

const Popup = ({ children, title, isOpen, onClose, onSubmit }) => {
  return (
    <>
      <div id="edit__profile" className={`popup ${isOpen && "popup_opened"}`}>
        <div className="popup__overlay"></div>
        <div className="popup__form">
          <button
            className="popup__close-btn popup__edit-close-btn"
            type="button"
            onClick={onClose}
          >
            <img className="popup__close-icon" src={close_icon} alt={""} />
          </button>
          <form
            onSubmit={onSubmit}
            className="popup__edit-about-form popup-form"
            name="EditAbout"
          >
            <h2 className="popup__form-label">{title}</h2>
            {children}
            <button className="popup__save-btn about__save-btn" type="submit">
              Save
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Popup;
