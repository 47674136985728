import React from 'react';
import { useContext } from 'react';
import AppThemeColourContext from '../../contexts/AppThemeColour';


const SocialIconButton = ({ name, url }) => {

  const themeColour = useContext(AppThemeColourContext)

  return (
    <a
      href={url}
      data-id={name}
      className={`cookieclick social__${name}`}
      style={{color: `${themeColour}`}}
      title="Facebook"
      target="_blank"
      rel="noreferrer"
    >
      <i className={`fab fa-${name}`}></i>
      <span className="sr-only"></span>
    </a>
  );
};

export default SocialIconButton;
