import "./Lead.css";

import React from "react";
import { useContext } from "react";
import AppThemeColourContext from "../../contexts/AppThemeColour";

const Lead = ({ handleExchangeContact }) => {
  const themeColour = useContext(AppThemeColourContext);

  return (
    <section className="page-section buttons__container">
      <div className="container relative">
        <div className="row">
          <div className="buttons__share">
            <button
              className="buttons__share_md btn btn-mod btn-border btn-round btn-medium"
              target="_self"
            >
              Save Contact
            </button>
            <button
              onClick={handleExchangeContact}
              className="buttons__share_big buttons__share_orange btn btn-mod btn-border btn-round btn-medium"
              target="_blank"
              style={{ backgroundColor: `${themeColour}` }}
            >
              Exchange Contact
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Lead;
