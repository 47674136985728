import "./Navigation.css";

import React from "react";

const Navigation = () => {
  return (
    <nav className="main-nav dark transparent js-transparent">
      <div className="full-wrapper relative">
        <div className="nav-logo-wrap">
          <a href="/" className="logo">
            <h3 className="nav__logo-text">LinkWi</h3>
          </a>
        </div>
        <div className="mobile-nav" role="button">
          <a
            className="nav__cta_big btn btn-mod btn-border btn-round btn-medium"
            target="_self"
          >
            GET YOUR CARD
          </a>

          <span className="sr-only">Menu</span>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
