import "./Link.css";
import placeholderImage from "../../images/icons/link-icon.jpg";

import React, { useState } from "react";
import { useContext } from "react";
import AppThemeColourContext from "../../contexts/AppThemeColour";

const Link = ({ onEditClick, editMode, link, type }) => {
  const { id, title, url, img, icon } = link;
  const [doesImageExist, setDoesImageExist] = useState(true);
  const themeColour = useContext(AppThemeColourContext);

  const handleLinkEditClick = () => {
    onEditClick({ id, title, url, img });
  };

  const linkImage = `https://logo.clearbit.com/${
    url.match(/^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/)[0]
  }`;

  return (
    <div className="col-md-6 mb-sm-20 mb-30">
      <div className="link">
        <div className="link__image-container">
          {img != null ? (
            <img
              id=""
              className="link__image"
              src={
                img
                  ? `https://linkwi.co/LINKWI/images/profile-links/${img}`
                  : doesImageExist
                  ? linkImage
                  : placeholderImage
              }
              alt=""
              onError={() => setDoesImageExist(false)}
            />
          ) : (
            <i className="files__url files__icon far fa-fw fa-file-pdf"></i>
          )}

          <i data-id="" className="hide links__delete fa fa-trash"></i>
          <i
            data-id=""
            className={`link__delete ${
              editMode && "link__delete_visible"
            } fa fa-trash`}
          ></i>
        </div>
        <p id="" className="link__text">
          {title}
        </p>
        <a
          data-id=""
          id=""
          className={`${
            editMode && "hide"
          } link__right-arrow links__url linkcookieclick`}
          href={url}
          target="_blank"
          rel="noreferrer"
          style={{ color: `${themeColour}` }}
        >
          <i
            className={`link__edit-icons fa ${
              type === "link"
                ? "fa-arrow-circle-right"
                : "fa-cloud-download-alt"
            }`}
            aria-hidden="true"
          ></i>
        </a>
        <span
          data-id=""
          className={`link__right-arrow ${!editMode && "hide"} links__url-edit`}
          style={{ color: `${themeColour}` }}
          onClick={handleLinkEditClick}
        >
          <i className="link__edit-icons fas fa-pencil-alt"></i>
        </span>
      </div>
    </div>
  );
};

export default Link;
