import "./EditFilesPopup.css";
import React from "react";
import Popup from "../Popup/Popup";

const EditFilesPopup = ({ isOpen, onClose, fileTitle, onTitleChange }) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log({ fileTitle });
    onClose();
  };

  return (
    <Popup
      title="Edit Files"
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit}
    >
      <input
        id="file-title_edit-input"
        className="popup__form-input popup__form-input_type_file_title"
        name="filename"
        type="text"
        placeholder="File title"
        onChange={(event) => {
          onTitleChange(event.target.value);
        }}
        value={fileTitle}
        required
      />
      <span className="input_error file-title_edit-input-error"></span>
    </Popup>
  );
};

export default EditFilesPopup;
