import React from "react";
import "./FileUpload.css";
import Link from "../Link/Link";

import { useState, useEffect } from "react";
import Axios from "axios";

const FileUpload = ({ handleFilesEdit, handleFilesAdd, uniqueid }) => {
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [fileData, setfileData] = useState([]);

  useEffect(() => {
    Axios.get(`http://localhost:3002/api/files/${uniqueid}`).then((data) => {
      setfileData(
        data.data.map((item, i) => {
          return {
            id: item.id,
            icon: item.icon,
            url: item.files,
            title: item.name,
          };
        })
      );
    });
  }, [uniqueid]);

  const toggleEditMode = () => {
    setIsInEditMode(!isInEditMode);
  };

  // const fileData = [
  //   {
  //     id: 24,
  //     title: "Makeup Secrets",
  //     url: "https://linkwi.co/LINKWI/files/055secretstopreppingyourskinforflawlessmakeup(article)authorvariousauthors-3240.pdf-508.pdf",
  //   },
  //   {
  //     id: 25,
  //     title: "7 Skin Care Tips",
  //     url: "https://linkwi.co/LINKWI/files/07skincaretips-tonremovemakeup-9301.pdf",
  //   },
  // ];

  return (
    <section className="page-section">
      <div className="container relative">
        <div id="display_file_uploads" className="files__list row">
          <div className="files__title">
            <h4 className="files__heading">Files</h4>
            <button
              className={`files__edit-btn ${isInEditMode && "hide"}`}
              onClick={toggleEditMode}
            />
            <i
              className={`${
                !isInEditMode && "hide"
              } files__edit-close fas fa-times`}
              onClick={toggleEditMode}
            />
          </div>

          {fileData.map((file) => (
            <Link
              link={file}
              key={file.id}
              editMode={isInEditMode}
              onEditClick={handleFilesEdit}
              type="file"
            />
          ))}
        </div>
        <button
          onClick={handleFilesAdd}
          className={`files__add ${isInEditMode && "files__add_visible"}`}
        >
          <i className="fas fa-plus-circle"></i>
        </button>
      </div>
    </section>
  );
};

export default FileUpload;
