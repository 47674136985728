import React from "react";
import "./EditProfilePopup.css";
import Popup from "../Popup/Popup";
import Axios from "axios";

const EditProfilePopup = ({
  isOpen,
  onClose,
  setEditProfile,
  editProfile,
  setUser,
  username,
  defaultProfileImage,
}) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Submitted: ", editProfile);
    Axios.patch(`http://localhost:3002/api/update/${username}`, {
      firstname: editProfile.firstname,
      lastname: editProfile.lastname,
      job: editProfile.job,
      organization: editProfile.organization,
      industry: editProfile.industry,
    });
    setUser({
      firstname: editProfile.firstname,
      lastname: editProfile.lastname,
      job: editProfile.job,
      organization: editProfile.organization,
      industry: editProfile.industry,
    });
    onClose();
  };
  const handleInputChange = (event) => {
    setEditProfile({
      ...editProfile,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };

  return (
    <Popup
      title="Edit Profile"
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit}
    >
      <img
        className="profile_output_image mb-3"
        data-toggle="tooltip"
        data-placement="top"
        title="User image"
        width="100px"
        src={defaultProfileImage}
      />
      <input
        id="image-input"
        className="profile__image-input profile__image_file popup__form-input"
        name="profile_img"
        type="file"
        accept="image/png, image/gif, image/jpeg"
      />
      <span className="input_error image-input-error"></span>
      <input
        id="firstname-input"
        className="popup__form-input"
        name="firstname"
        type="text"
        placeholder="First name"
        value={editProfile.firstname}
        onChange={handleInputChange}
        required
      />
      <span className="input_error firstname-input-error"></span>
      <input
        id="lastname-input"
        className="popup__form-input"
        name="lastname"
        type="text"
        placeholder="Last Name"
        value={editProfile.lastname}
        onChange={handleInputChange}
        required
      />
      <span className="input_error lastname-input-error"></span>
      <input
        id="title-input"
        className="popup__form-input"
        name="job"
        type="text"
        placeholder="Job title"
        value={editProfile.job}
        onChange={handleInputChange}
        required
      />
      <span className="input_error title-input-error"></span>
      <input
        id="organization-input"
        className="popup__form-input"
        name="organization"
        type="text"
        placeholder="Organization"
        value={editProfile.organization}
        onChange={handleInputChange}
        required
      />
      <span className="input_error organization-input-error"></span>
      <input
        id="industry-input"
        className="popup__form-input"
        name="industry"
        type="text"
        placeholder="Industry Type"
        value={editProfile.industry}
        onChange={handleInputChange}
        required
      />
      <span className="input_error industry-input-error"></span>
    </Popup>
  );
};

export default EditProfilePopup;
