import React from "react";
import "./SocialIconsPopup.css";
import { useState } from "react";
import Popup from "../Popup/Popup";
import SocialLinkInput from "../SocialLinkInput/SocialLinkInput";

const SocialIconsPopup = ({ isOpen, onClose }) => {
  const [socialLinks, setSocialLinks] = useState({
    facebook: "",
    twitter: "",
    instagram: "",
    linkedin: "",
    tiktok: "",
    youtube: "",
    whatsapp: "",
  });

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setSocialLinks({ ...socialLinks, [name]: value });
  };

  const handleSocialLinksSubmit = (event) => {
    event.preventDefault();
    console.log(socialLinks);
    onClose();
  };

  const socialNetworks = [
    { name: "facebook", placeholder: "Facebook URL" },
    { name: "twitter", placeholder: "Twitter URL" },
    { name: "instagram", placeholder: "Instagram URL" },
    { name: "linkedin", placeholder: "LinkedIn URL" },
    { name: "tiktok", placeholder: "TikTok URL" },
    { name: "youtube", placeholder: "YouTube URL" },
    { name: "whatsapp", placeholder: "WhatsApp" },
  ];

  return (
    <Popup
      title="Social Icons"
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSocialLinksSubmit}
    >
      {socialNetworks.map((socialNetwork) => {
        const { name, placeholder } = socialNetwork;
        return (
          <SocialLinkInput
            onChange={handleFormChange}
            name={name}
            placeholder={placeholder}
            key={name}
          />
        );
      })}
    </Popup>
  );
};

export default SocialIconsPopup;
