import "./AddLinkPopup.css";
import linkPlaceholder from "../../images/icons/link-icon.jpg";
import React from "react";
import Popup from "../Popup/Popup";

const AddLinkPopup = ({ isOpen, onClose }) => {
  return (
    <Popup title="Add Link" isOpen={isOpen} onClose={onClose}>
      <img
        className="links__output_image_add mb-3"
        data-toggle="tooltip"
        data-placement="top"
        title="User image"
        width="100px"
        src={linkPlaceholder}
        alt="link icon"
      />
      <input
        id="image_add-input"
        className="popup__form-input popup__form-input_type_link_file"
        name="link_file"
        type="file"
        accept="image/png, image/gif, image/jpeg"
        required
      />
      <span className="input_error image_add-input-error"></span>
      <input
        id="title_add-input"
        className="popup__form-input popup__form-input_type_link_title"
        name="link_title"
        type="text"
        placeholder="Link title"
        required
      />
      <span className="input_error title_add-input-error"></span>
      <input
        id="url_add-input"
        className="popup__form-input popup__form-input_type_link_url"
        name="link_url"
        type="url"
        placeholder="url"
        required
      />
      <span className="input_error url_add-input-error"></span>
    </Popup>
  );
};

export default AddLinkPopup;
