import "./ChangeThemePopup.css";
import React from "react";
import FooterPopup from "../FooterPopup/FooterPopup";
import { CirclePicker } from "react-color";

const ChangeThemePopup = ({ isOpen, onClose, onSelect }) => {
  const handleColourSelection = (color) => {
    onSelect(color.hex);
    onClose();
  };

  // [material.red['500'], material.pink['500'], material.purple['500'], material.deepPurple['500'], material.indigo['500'], material.blue['500'], material.lightBlue['500'], material.cyan['500'], material.teal['500'], material.green['500'], material.lightGreen['500'], material.lime['500'], material.yellow['500'], material.amber['500'], material.orange['500'], material.deepOrange['500'], material.brown['500'], material.blueGrey['500']]

  return (
    <FooterPopup title="Edit Theme" isOpen={isOpen} onClose={onClose}>
      <div className="footer-popup__selection">
        <p className="footer-popup__text">Customize your LinkWi app</p>
        <p className="footer-popup__text">Please choose a color</p>

        <div className="footer-popup__column">
          <CirclePicker
            onChange={handleColourSelection}
            circleSpacing={20}
            circleSize={42}
            width={`90%`}
          />
          {/* <div className="footer-popup__circle footer-popup__blue" data-id="0d6efd">
            <span className="footer-popup__name">Blue</span>
          </div>
          <div className="footer-popup__circle footer-popup__indigo" data-id="6610f2">
            <span className="footer-popup__name">Indogo</span>
          </div>
          <div className="footer-popup__circle footer-popup__purple" data-id="6f42c1">
            <span className="footer-popup__name">Purple</span>
          </div>
          <div className="footer-popup__circle footer-popup__pink" data-id="d63384">
            <span className="footer-popup__name">Pink</span>
          </div>
          <div className="footer-popup__circle footer-popup__red" data-id="dc3545">
            <span className="footer-popup__name">Red</span>
          </div>
        </div>
        <div className="footer-popup__column">
          <div className="footer-popup__circle footer-popup__orange" data-id="fd7e14">
            <span className="footer-popup__name">Orange</span>
          </div>
          <div className="footer-popup__circle footer-popup__yellow" data-id="ffc107">
            <span className="footer-popup__name">Yellow</span>
          </div>
          <div className="footer-popup__circle footer-popup__gold" data-id="f6bb42">
            <span className="footer-popup__name">Gold</span>
          </div>
          <div className="footer-popup__circle footer-popup__green" data-id="198754">
            <span className="footer-popup__name">Green</span>
          </div>
          <div className="footer-popup__circle footer-popup__teal" data-id="20c997">
            <span className="footer-popup__name">Teal</span>
          </div> */}
        </div>
      </div>
    </FooterPopup>
  );
};

export default ChangeThemePopup;
