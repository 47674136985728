import "./EditLinksPopup.css";
import linkPlaceholder from "../../images/icons/link-icon.jpg";
import { React, useState } from "react";
import Popup from "../Popup/Popup";

const EditLinksPopup = ({
  isOpen,
  onClose,
  linkTitle,
  linkUrl,
  onTitleChange,
  onUrlChange,
}) => {
  // const handleTitleChange = (event) => {
  //   setLinkTitle(event.target.value);
  // };

  // const handleUrlChange = (event) => {
  //   setlinkUrl(event.target.value);
  // };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log({ linkTitle, linkUrl });
    onClose();
  };

  return (
    <Popup
      title="Edit Links"
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit}
    >
      <img
        className="links__output_image_edit mb-3"
        data-toggle="tooltip"
        data-placement="top"
        title="User image"
        width="100px"
        src={linkPlaceholder}
        alt="link icon"
      />
      <input
        id="image_edit-input"
        // onchange="popupImagePreview(event,popupLinksEditImage,popupLinksEditPreviewImage)"
        className="links__input links__input_file"
        name="link_file"
        type="file"
        accept="image/png, image/gif, image/jpeg"
      />
      <span className="input_error image_edit-input-error"></span>
      <input
        id="title_edit-input"
        className="links__input"
        name="link_title"
        type="text"
        placeholder="Title"
        required
        value={linkTitle}
        onChange={(event) => {
          onTitleChange(event.target.value);
        }}
      />
      <span className="input_error title_edit-input-error"></span>
      <input
        id="url_edit-input"
        className="links__input"
        name="link_url"
        type="url"
        placeholder="Enter your link here"
        required
        value={linkUrl}
        onChange={(event) => {
          onUrlChange(event.target.value);
        }}
      />
      <span className="input_error url_edit-input-error"></span>
    </Popup>
  );
};

export default EditLinksPopup;
