import './SocialLinkInput.css';

import React from 'react';

const SocialLinkInput = ({ name, value, placeholder, onChange }) => {

  return (
    <div className="social-input">
      <span className="social-input__icon">
        <i className={`fab fa-${name}`}></i>
      </span>
      <input
        id={`${name}-input`}
        className="social-input__field"
        name={name}
        type="url"
        placeholder={placeholder}
        onChange= {onChange}
        value={value}
      />
    </div>
  );
};

export default SocialLinkInput;
