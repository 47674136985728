import React from "react";
import "./Footer.css";

const Footer = ({ handleShowLogo, defaultLogo }) => {
  return (
    <footer className="page-section footer bg-gray-lighter ">
      <div className="container">
        <div className="footer__text">
          <div className="footer__copyright">
            <a href="/">&copy; LinkWi {new Date().getFullYear()}</a>.
          </div>
          <div className="footer__made">Imagination driven</div>
        </div>
      </div>
      <div
        style={{ backgroundImage: `url(${defaultLogo})` }}
        className="footer__logo"
        onClick={handleShowLogo}
      ></div>
    </footer>
  );
};

export default Footer;
