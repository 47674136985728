import React from "react";
import Header from "../Header/Header";
import Main from "../Main/Main";
import Footer from "../Footer/Footer";
import StickyFooter from "../StickyFooter/StickyFooter";
import AboutMePopup from "../AboutMePopup/AboutMePopup";
import EditContactPopup from "../EditContactPopup/EditContactPopup";
import EditProfilePopup from "../EditProfilePopup/EditProfilePopup";
import SocialIconsPopup from "../SocialIconsPopup/SocialIconsPopup";
import EditLinksPopup from "../EditLinksPopup/EditLinksPopup";
import EditFilesPopup from "../EditFilesPopup/EditFilesPopup";
import FileUploadPopup from "../FileUploadPopup/FileUploadPopup";
import ImagePopup from "../ImagePopup/ImagePopup";
import ExchangeContactPopup from "../ExchangeContactPopup/ExchangeContactPopup";
import defaultProfileImage from "../../images/profileImages/defaultProfileImage.png";
import defaultLogo from "../../images/logos/sampleLogo.jpg";
import ChangeThemePopup from "../ChangeThemePopup/ChangeThemePopup";
import AddLinkPopup from "../AddLinkPopup/AddLinkPopup";
import AppThemeColourContext from "../../contexts/AppThemeColour";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Axios from "axios";

function MainPage() {
  let { username } = useParams();
  const [user, setUser] = useState({});
  const [aboutUser, setAboutUser] = useState({});
  const [contactUser, setContactUser] = useState({});

  useEffect(() => {
    Axios.get(`https://api.linkwi.kerwindows.com/api/user/${username}`).then(
      function (item) {
        setUser({
          uniqueid: item.data.UniqueID,
          firstname: item.data.FirstName,
          lastname: item.data.LastName,
          job: item.data.Job,
          industry: item.data.IndustryType,
          organization: item.data.Organization,
          img: item.data.ProfileImage,
        });
        setAboutUser({
          bio: item.data.Bio,
        });
        setContactUser({
          phone: item.data.BusinessContact,
          email: item.data.BusinessEmailAddress,
          logo: item.data.Image_one,
        });
      }
    );
  }, [username]);
  //popup states
  const [isAboutMePopupOpen, setIsAboutMePopupOpen] = useState(false);
  const [isEditContactPopupOpen, setIsEditContactPopupOpen] = useState(false);
  const [isEditProfilePopupOpen, setIsEditProfilePopupOpen] = useState(false);
  const [isSocialIconsPopupOpen, setIsSocialIconsPopupOpen] = useState(false);
  const [isEditLinksPopupOpen, setIsEditLinksPopupOpen] = useState(false);
  const [isEditFilesPopupOpen, setIsEditFilesPopupOpen] = useState(false);
  const [isAddFilesPopupOpen, setIsAddFilesPopupOpen] = useState(false);
  const [isLogoPopupOpen, setIsLogoPopupOpen] = useState(false);
  const [isProfileImagePopupOpen, setIsProfileImagePopupOpen] = useState(false);
  const [isExchangeContactPopupOpen, setIsExchangeContactPopupOpen] =
    useState(false);
  const [isChangeThemePopupOpen, setIsChangeThemePopupOpen] = useState(false);
  const [isAddLinkPopupOpen, setIsAddLinkPopupOpen] = useState(false);

  const [linkTitle, setLinkTitle] = useState("");
  const [linkUrl, setLinkUrl] = useState("");
  const [fileTitle, setFileTitle] = useState("");
  const [editProfile, setEditProfile] = useState({
    firstname: "",
    lastname: "",
    job: "",
    industry: "",
    organization: "",
    img: "",
  });
  const [editAboutMe, setEditAboutMe] = useState({
    bio: "",
  });
  const [editContact, setEditContact] = useState({
    phone: "",
    email: "",
    logo: "",
  });

  // App States
  const [themeColour, setThemeColour] = useState("");

  // Popup Handlers
  const handleAboutMeEdit = (aboutMeData) => {
    setEditAboutMe(aboutMeData);
    console.log(aboutMeData);
    setIsAboutMePopupOpen(true);
  };

  const handleEditContact = (contactData) => {
    setEditContact(contactData);
    setIsEditContactPopupOpen(true);
  };
  const handleEditProfileEdit = (profileData) => {
    setEditProfile(profileData);
    setIsEditProfilePopupOpen(true);
  };
  const handleSocialIconsEdit = () => {
    setIsSocialIconsPopupOpen(true);
  };

  const handleLinksEdit = (linkData) => {
    setLinkTitle(linkData.title);
    setLinkUrl(linkData.url);
    setIsEditLinksPopupOpen(true);
  };

  const handleFilesEdit = (fileData) => {
    setFileTitle(fileData.title);
    setIsEditFilesPopupOpen(true);
  };
  const handleFilesAdd = () => {
    setIsAddFilesPopupOpen(true);
  };
  const handleShowLogo = () => {
    setIsLogoPopupOpen(true);
  };
  const handleShowProfileImage = () => {
    setIsProfileImagePopupOpen(true);
  };
  const handleExchangeContact = () => {
    setIsExchangeContactPopupOpen(true);
  };
  const handleChangeTheme = () => {
    setIsChangeThemePopupOpen(true);
  };
  const handleAddLink = () => {
    setIsAddLinkPopupOpen(true);
  };
  const isAnyPopupOpen =
    isAboutMePopupOpen ||
    isEditProfilePopupOpen ||
    isEditContactPopupOpen ||
    isSocialIconsPopupOpen ||
    isEditLinksPopupOpen ||
    isEditFilesPopupOpen ||
    isAddFilesPopupOpen ||
    isLogoPopupOpen ||
    isProfileImagePopupOpen ||
    isExchangeContactPopupOpen ||
    isAddLinkPopupOpen ||
    isChangeThemePopupOpen;

  const closeAllPopups = () => {
    setIsAboutMePopupOpen(false);
    setIsEditProfilePopupOpen(false);
    setIsEditContactPopupOpen(false);
    setIsSocialIconsPopupOpen(false);
    setIsEditLinksPopupOpen(false);
    setIsEditFilesPopupOpen(false);
    setIsAddFilesPopupOpen(false);
    setIsLogoPopupOpen(false);
    setIsProfileImagePopupOpen(false);
    setIsExchangeContactPopupOpen(false);
    setIsChangeThemePopupOpen(false);
    setIsAddLinkPopupOpen(false);
  };

  useEffect(() => {
    const handleClickClose = (event) => {
      event.target.classList.contains("popup__overlay") && closeAllPopups();
    };

    const handleEscapeClose = (event) => {
      event.key === "Escape" && closeAllPopups();
    };

    if (isAnyPopupOpen) {
      document.addEventListener("click", handleClickClose);
      document.addEventListener("keydown", handleEscapeClose);
    }
    return () => {
      document.removeEventListener("click", handleClickClose);
      document.removeEventListener("keydown", handleEscapeClose);
    };
  }, [isAnyPopupOpen]);

  const handleColourChange = (colour) => {
    setThemeColour(colour);
  };

  return (
    <div>
      <Header />
      <AppThemeColourContext.Provider value={themeColour}>
        <Main
          user={user}
          aboutUser={aboutUser}
          defaultProfileImage={
            user.img
              ? `https://linkwi.co/LINKWI/images/profile-images/${user.img}`
              : defaultProfileImage
          }
          handleAboutMeEdit={handleAboutMeEdit}
          handleSocialIconsEdit={handleSocialIconsEdit}
          handleLinksEdit={handleLinksEdit}
          handleFilesEdit={handleFilesEdit}
          handleShowProfileImage={handleShowProfileImage}
          handleExchangeContact={handleExchangeContact}
          handleEditProfileEdit={handleEditProfileEdit}
          handleAddLink={handleAddLink}
          handleFilesAdd={handleFilesAdd}
        />
        <Footer
          handleShowLogo={handleShowLogo}
          defaultLogo={
            contactUser.logo
              ? `https://linkwi.co/LINKWI/images/profile-logos/${contactUser.logo}`
              : defaultLogo
          }
        />
        <StickyFooter
          contactUser={contactUser}
          handleChangeTheme={handleChangeTheme}
          handleEditContact={handleEditContact}
        />
      </AppThemeColourContext.Provider>

      <AboutMePopup
        isOpen={isAboutMePopupOpen}
        onClose={closeAllPopups}
        setAboutUser={setAboutUser}
        editAboutMe={editAboutMe}
        setEditAboutMe={setEditAboutMe}
        handleAboutMeEdit={handleAboutMeEdit}
        username={username}
      />
      <SocialIconsPopup
        isOpen={isSocialIconsPopupOpen}
        onClose={closeAllPopups}
      />
      <EditLinksPopup
        isOpen={isEditLinksPopupOpen}
        onClose={closeAllPopups}
        linkTitle={linkTitle}
        linkUrl={linkUrl}
        onTitleChange={setLinkTitle}
        onUrlChange={setLinkUrl}
      />
      <EditFilesPopup
        isOpen={isEditFilesPopupOpen}
        onTitleChange={setFileTitle}
        onClose={closeAllPopups}
        fileTitle={fileTitle}
      />
      <FileUploadPopup isOpen={isAddFilesPopupOpen} onClose={closeAllPopups} />
      <ImagePopup
        defaultLogo={
          contactUser.logo
            ? `https://linkwi.co/LINKWI/images/profile-logos/${contactUser.logo}`
            : defaultLogo
        }
        isOpen={isLogoPopupOpen}
        onClose={closeAllPopups}
      />
      <ImagePopup
        defaultProfileImage={
          user.img
            ? `https://linkwi.co/LINKWI/images/profile-images/${user.img}`
            : defaultProfileImage
        }
        isOpen={isProfileImagePopupOpen}
        onClose={closeAllPopups}
      />
      <ExchangeContactPopup
        isOpen={isExchangeContactPopupOpen}
        onClose={closeAllPopups}
      />
      <ChangeThemePopup
        isOpen={isChangeThemePopupOpen}
        onClose={closeAllPopups}
        onSelect={handleColourChange}
      />
      <EditContactPopup
        isOpen={isEditContactPopupOpen}
        onClose={closeAllPopups}
        editContact={editContact}
        setEditContact={setEditContact}
        setContactUser={setContactUser}
        defaultLogo={
          contactUser.logo
            ? `https://linkwi.co/LINKWI/images/profile-logos/${contactUser.logo}`
            : defaultLogo
        }
      />
      <EditProfilePopup
        isOpen={isEditProfilePopupOpen}
        onClose={closeAllPopups}
        setUser={setUser}
        editProfile={editProfile}
        setEditProfile={setEditProfile}
        username={username}
        handleEditProfileEdit={handleEditProfileEdit}
        defaultProfileImage={
          user.img
            ? `https://linkwi.co/LINKWI/images/profile-images/${user.img}`
            : defaultProfileImage
        }
      />
      <AddLinkPopup isOpen={isAddLinkPopupOpen} onClose={closeAllPopups} />
    </div>
  );
}

export default MainPage;
