import React from "react";
import "./FileUploadPopup.css";
import Popup from "../Popup/Popup";

const FileUploadPopup = ({ isOpen, onClose }) => {
  return (
    <Popup title="Upload File" isOpen={isOpen} onClose={onClose}>
      <input
        id="file-input"
        type="file"
        name="fileupload"
        id="fileupload"
        className="popup__form-input file-input"
        required
      />
      <span className="input_error file-input-error"></span>
      <input
        id="name-input"
        className="popup__form-input popup__form-input_type_file-name"
        name="filename"
        type="text"
        placeholder="File name"
        required
      />
      <span className="input_error name-input-error"></span>
    </Popup>
  );
};

export default FileUploadPopup;
