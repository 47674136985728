import "./AboutMePopup.css";
import React from "react";
import Popup from "../Popup/Popup";
import Axios from "axios";

const AboutMePopup = ({
  isOpen,
  onClose,
  setAboutUser,
  editAboutMe,
  setEditAboutMe,
  username,
}) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Submitted: ", editAboutMe);
    Axios.patch(`http://localhost:3002/api/bio/${username}`, {
      bio: editAboutMe.bio,
    });
    setAboutUser({
      bio: editAboutMe.bio,
    });
    onClose();
  };
  const onAboutMeChange = (event) => {
    setEditAboutMe({
      bio: event.target.value,
    });
  };

  return (
    <Popup
      title="About Me"
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit}
    >
      <textarea
        name="bio"
        id="bio-input"
        className="popup__form-textarea popup__form-input"
        placeholder="About Me"
        required
        onChange={onAboutMeChange}
        value={editAboutMe.bio}
      ></textarea>
      <span className="input_error bio-input-error"></span>
    </Popup>
  );
};

export default AboutMePopup;
