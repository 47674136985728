import React from "react";
import iconPin from "../../images/icons/icon_pin.svg";
import edit from "../../images/icons/edit.svg";
import bannerImage from "../../images/backgrounds/bannerImage.jpg";
import "./Profile.css";

const Profile = ({
  user,
  handleEditProfileEdit,
  handleShowProfileImage,
  defaultProfileImage,
}) => {
  const handleEditProfileEditClick = () => {
    handleEditProfileEdit({
      firstname: user.firstname,
      lastname: user.lastname,
      job: user.job,
      industry: user.industry,
      organization: user.organization,
      img: user.img,
    });
  };

  return (
    <section className="profile__banner page-section">
      <img
        className="profile__background-image"
        src={bannerImage}
        alt="Banner"
      />
      <div className="profile__container container">
        <div className="profile__header">
          <article
            className="profile__image-container"
            style={{
              backgroundImage: `url(${defaultProfileImage})`,
            }}
            onClick={handleShowProfileImage}
          ></article>
          <article className="profile__text-content">
            <div className="">
              <div>
                <div className="profile__name mb-20 mb-xs-10">
                  <h2 className="profile__firstname">{user.firstname}</h2>
                  <h2 className="profile__lastname">{user.lastname}</h2>
                </div>
              </div>
              <div>
                <p className="profile__title hs-line-6 opacity-075 mb-20 mb-xs-0">
                  {user.job}
                </p>
              </div>
            </div>
            <div className="mt-10">
              <div className="mod-breadcrumbs profile__location">
                <img className="profile__map-pin" src={iconPin} alt="pin" />
                <span className="profile__organization">
                  {user.organization}
                </span>
                <span className="profile__breadcrumbs-dot mod-breadcrumbs-slash">
                  {" ● "}
                </span>
                <span className="profile__city">{user.industry}</span>
              </div>
            </div>
          </article>
          <div
            className="profile__edit_light profile__edit-btn"
            onClick={handleEditProfileEditClick}
          >
            <img src={edit} alt="Edit" title="Edit Profile" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Profile;
