import React from "react";
import closeIcon from "../../images/icons/close-icon.svg";
import loader from "../../images/extras/loader.png";
import "./ImagePopup.css";

const ImagePopup = ({ isOpen, onClose, defaultProfileImage, defaultLogo }) => {
  return (
    <div id="view__image" className={`popup ${isOpen && "popup_opened"}`}>
      <div className="popup__overlay"></div>
      <div className="popup__form popup__form_image">
        <button
          className="popup__close-btn popup__image-close-btn"
          type="button"
          onClick={onClose}
        >
          <img
            className="popup__close-icon"
            src={closeIcon}
            alt="close button"
          />
        </button>
        <img
          className="popup__card-image-preview"
          src={defaultProfileImage || defaultLogo || { loader }}
          alt={"Popup photo"}
        />
      </div>
    </div>
  );
};

export default ImagePopup;
