import "./EditContactPopup.css";

import React, { useState } from "react";
import FooterPopup from "../FooterPopup/FooterPopup";

const EditContactPopup = ({
  isOpen,
  onClose,
  editContact,
  setEditContact,
  setContactUser,
  defaultLogo,
}) => {
  const handleOnChange = (event) => {
    setEditContact(...editContact, ([event.target.name]: event.target.value));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const thishere = setContactUser(...editContact, {
      [event.target.name]: event.target.value,
    });
    console.log(thishere);
    onClose();
  };

  return (
    <FooterPopup
      title="Edit Contact Information"
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit}
    >
      <div className="footer-popup__selection footer-popup__form-container">
        <form
          method="POST"
          acceptCharset="UTF-8"
          className="popup__edit-userinfo popup-form"
          name="UserInfo"
        >
          <img
            id="uploaded_image"
            className="userinfo_output_image mb-3"
            data-toggle="tooltip"
            data-placement="top"
            title="User image"
            width="100px"
            src={
              editContact.logo
                ? `https://linkwi.co/LINKWI/images/profile-logos/${editContact.logo}`
                : defaultLogo
            }
            alt="link icon"
          />
          <input
            id="userinfo-image-input"
            className="image popup__form-input"
            name="userinfo_img"
            type="file"
            accept="image/png, image/gif, image/jpeg"
          />
          <br />
          <span className="input_error userinfo-image-input-error"></span>

          <input
            id="email-input"
            className="mb-0 popup__form-input"
            name="email"
            type="email"
            placeholder="Business Email"
            value={editContact.email}
            onChange={handleOnChange}
            required
          />
          <br />
          <span className="input_error email-input-error"></span>
          <br />
          <input
            id="contact-input"
            className="popup__form-input"
            name="phone"
            type="tel"
            placeholder="Business phone contact (optional)"
            value={editContact.phone}
            onChange={handleOnChange}
          />
          <br />
          <span className="input_error contact-input-error"></span>
          <button
            className="popup__save-btn userinfo__save-btn mt-0"
            type="submit"
          >
            Save
          </button>
        </form>
      </div>
    </FooterPopup>
  );
};

export default EditContactPopup;
