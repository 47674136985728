import React from "react";
import "./SocialIcons.css";
import edit from "../../images/icons/edit.svg";
import SocialIconButton from "../SocialIconButton/SocialIconButton";

const SocialIcons = ({ handleSocialIconsEdit }) => {
  const socialNetworks = [
    { name: "facebook", url: "Facebook URL" },
    { name: "twitter", url: "Twitter URL" },
    { name: "instagram", url: "Instagram URL" },
    { name: "linkedin", url: "LinkedIn URL" },
    { name: "tiktok", url: "TikTok URL" },
    { name: "youtube", url: "YouTube URL" },
    { name: "whatsapp", url: "WhatsApp" },
  ];
  return (
    <div className="col-md-5 col-lg-6">
      <div className="social__title">
        <h4 className="social__heading">Social Links</h4>
        <div
          className="social__edit_dark social__edit-btn"
          onClick={handleSocialIconsEdit}
        >
          <img src={edit} alt="Edit" title="Edit Socials" />
        </div>
      </div>
      <div className="social-links mb-xs-20">
        {socialNetworks.map((network) => {
          const { name, url } = network;
          return <SocialIconButton name={name} url={url} key={name} />;
        })}
      </div>
    </div>
  );
};

export default SocialIcons;
