import React from "react";
import "./Links.css";
import edit from "../../images/icons/edit.svg";
import Link from "../Link/Link";

import { useState, useEffect } from "react";
import Axios from "axios";

const Links = ({ handleLinksEdit, handleAddLink, uniqueid }) => {
  const [linkData, setlinkData] = useState([]);

  useEffect(() => {
    Axios.get(`http://localhost:3002/api/links/${uniqueid}`).then((data) => {
      setlinkData(
        data.data.map((item) => {
          return {
            id: item.id,
            img: item.link_img,
            url: item.link_url,
            title: item.link_title,
          };
        })
      );
    });
  }, [uniqueid]);
  const [isInEditMode, setIsInEditMode] = useState(false);

  const toggleEditMode = () => {
    setIsInEditMode(!isInEditMode);
  };

  // const linkData = [
  //   {
  //     id: 24,
  //     title: "View My Photo Album",
  //     url: "https://unsplash.com/photos/lQDwFkhSJrg",
  //   },
  //   {
  //     id: 25,
  //     title: "Support me on Patreon",
  //     url: "https://cuc.edu.tt/",
  //   },
  //   { id: 26, title: "My Articles", url: "https://medium.com" },
  // ];

  return (
    <section className="page-section">
      <div className="container relative">
        <div id="linkData" className="links__list row">
          <div className="links__title">
            <h4 className="Links__heading">Links</h4>
            <div className="links__edit_dark links__edit-btn">
              <button
                className={`${isInEditMode && "hide"} links__edit-open`}
                src={edit}
                alt="Edit"
                title="Edit Links"
                onClick={toggleEditMode}
              />
              <i
                className={`${
                  !isInEditMode && "hide"
                } links__edit-close fas fa-times`}
                onClick={toggleEditMode}
              />
            </div>
          </div>

          {linkData.map((link) => {
            return (
              <Link
                onEditClick={handleLinksEdit}
                editMode={isInEditMode}
                link={link}
                key={link.id}
                type="link"
              />
            );
          })}
        </div>
        <button
          onClick={handleAddLink}
          className={`${isInEditMode && "links__add_visible"} links__add`}
        >
          <i className="fas fa-plus-circle"></i>
        </button>
      </div>
    </section>
  );
};

export default Links;
