import React from "react";
import "./FooterPopup.css";
import close_icon from "../../images/icons/close-icon.svg";

const FooterPopup = ({ children, title, isOpen, onClose, onSubmit }) => {
  return (
    <>
      <div id="edit__colour" className={`popup ${isOpen && "popup_opened"}`}>
        <div className="popup__overlay"></div>
        <section
          id="footer-popup"
          className={`footer-popup ${isOpen && "footer-popup_opened"}`}
        >
          <h2 className="footer-popup__popup__form-label">{title}</h2>
          <button
            className="footer-popup__popup-close-btn popup__footer-close-btn"
            type="button"
            onClick={onClose}
          >
            <img className="popup__close-icon" src={close_icon} alt={""} />
          </button>
          {children}
        </section>
      </div>
    </>
  );
};

export default FooterPopup;
